






































































































import { defineComponent } from '@vue/composition-api'
import { Profession } from '@/api/interfaces/profession'
import { mapActions, mapState } from 'vuex'
import { ValidationObserver } from 'vee-validate'

export default defineComponent({
  name: 'DialogDeleteOrTransferProfession',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    profession: {
      type: Object as () => Profession,
      required: false,
      default: null,
    },
  },
  data () {
    return {
      targetProfession: null as Profession | null,
      isLoadingTransferAndDelete: false,
    }
  },
  methods: {
    ...mapActions('profession', {
      deleteProfession: 'delete',
      transferProfession: 'transfer',
    }),
    setOpenValue (opened: boolean) : void {
      this.$emit('input', opened)
    },
    async confirmDelete () : Promise<void> {
      try {
        this.isLoadingTransferAndDelete = true
        await this.transferProfession({
          idProfession: this.profession.id,
          idTargetProfession: (this.targetProfession as Profession).id,
        })
        await this.deleteProfession(this.profession.id)
        this.setOpenValue(false)
        this.$emit('deleted')
        this.targetProfession = null
        const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
        observer.reset()
      } finally {
        this.isLoadingTransferAndDelete = false
      }
    },
  },
  computed: {
    ...mapState('profession', {
      listProfessions: 'list',
    }),
    listTargetProfessionsFiltered () : Array<Profession> {
      // copy to avoid self references
      const listProfessions = (this.listProfessions as Array<Profession>).slice()
      return listProfessions.filter((profession) => profession.id !== this.profession.id)
    },
  },
})
